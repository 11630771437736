/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-styletto-performance-meets-portability-and-style",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto-performance-meets-portability-and-style",
    "aria-label": "signia styletto performance meets portability and style permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Styletto: Performance Meets Portability and Style"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-hands.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Meet the new behind-the-ear hearing aid that’s transforming the future of modern hearing aid design. Powered by Signia Nx, the Styletto is a revolution in hearing aid technology and performance. Designed for the active lifestyle, the Styletto combines elegance, convenience, and functionality to deliver a high-quality hearing experience without compromising style.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unlike any hearing aid you’ve seen before, this surprisingly slim device leverages modern battery charging technology inside a compact form factor to deliver a solution that looks more like a sleek Bluetooth earpiece than a hearing aid. This is truly a hearing aid that looks as good as it performs."), "\n", React.createElement(ButtonCta, {
    copy: "Try the Styletto now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Stylish and discreet behind-the-ear (BTE) design"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Up to 19 hours of continuous hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ World’s first SLIM-RIC (Slim Lithium Ion Module Receiver-in-canal) hearing aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Energy efficient, rechargeable Li-ion battery"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Own Voice Processing (OVP™) for natural own-voice sound"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Ultra HD e2e® binaural link providing: Clear speech understanding, enhanced spatial awareness and extended dynamic range"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Moisture and dust resistance for the active lifestyle"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Price: on demand"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "revolution-in-design",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#revolution-in-design",
    "aria-label": "revolution in design permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Revolution in Design"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-3-colors.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Meet the new behind-the-ear hearing aid that’s transforming the future of modern hearing aid design. Powered by Signia Nx, the Styletto is a revolution in hearing aid technology and performance. Designed for the active lifestyle, the Styletto combines elegance, convenience, and functionality to deliver a high-quality hearing experience without compromising style.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing aid doesn’t have to be one design fits all. The Signia Styletto lets you choose your own individual style. With three different color combinations available (see picture), break away from the norm and choose a look that’s uniquely you. With Styletto, you’re not only getting the best in technology and performance—you’re also making a fashion statement."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Although the Styletto may look small, it still delivers big results, with sound quality comparable to other products on the market. With an Ultra HD e2e® binaural link, hear speech clearly even in loud settings, increase spatial awareness, and extend your dynamic range. And since the Styletto is powered by Signia Nx technology, you’ll experience clear and natural sound in every situation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you’re in your home or out with friends, the Styletto’s Own Voice Processing (OVP™) technology allows you to hear every voice clearly. From live music to intimate conversations, enjoy superior sound quality and a true-to-life listening experience. And since the Styletto is moisture and dust resistant, it meets the demands of your active lifestyle."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "batteries-not-required",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#batteries-not-required",
    "aria-label": "batteries not required permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Batteries Not Required"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-hearing-aids.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Charging on the go is easy and convenient. There’s no need to always be near a power source when the power is in your pocket. And since you charge the Styletto rather than replace the battery, you’ll never need to buy a hearing aid battery again."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Designed specifically for customers with active lifestyles, the Styletto delivers up to 19 hours of continuous hearing. Combined with the portable charging case, which looks similar to modern earbud cases, you can enjoy up to 4 days of use. You can even place the Styletto in the case for 30 minutes for an extra 5 hours of additional use.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bluetooth-wireless-streaming-with-signia-styletto-connect-7nx",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bluetooth-wireless-streaming-with-signia-styletto-connect-7nx",
    "aria-label": "bluetooth wireless streaming with signia styletto connect 7nx permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bluetooth Wireless Streaming with Signia Styletto Connect 7NX"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-connect-charger-open.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is compatible with your Apple smartphone, then look no further than the Styletto Connect. Offering all the same great features and stylish design as the standard Styletto, the Connect gives you the added ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "benefit of Bluetooth streaming"), ". This direct connectivity to your smartphones allows for phone call and audio streaming. In addition, the StreamLine TV accessory feeds high-quality audio from your TV directly into your hearing aids.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "take-control-with-the-touchcontrol-app-and-minipocket-remote",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#take-control-with-the-touchcontrol-app-and-minipocket-remote",
    "aria-label": "take control with the touchcontrol app and minipocket remote permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Take Control with the touchControl App and miniPocket Remote"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/accessories-remote-controls.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Controlling your Styletto is just a fingertip away. Simply use the touchControl app on your smartphone or the miniPocket remote to adjust your settings to fit your surroundings. There’s no need to reach behind your ear to make volume adjustments. Just pull the miniPocket remote from your pocket and discreetly make the adjustments you need.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-hearing-experts-are-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-hearing-experts-are-here-to-help",
    "aria-label": "our hearing experts are here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Hearing Experts are Here to Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "No matter the degree of hearing loss you’re experiencing, you can enjoy exceptional quality hearing without compromising style. Contact us today to speak with one of our hearing experts and to find out if the Signia Styletto is right for you. Simply call us or click the “Get started” button below to see if you qualify for a 30-day risk-free trial."), "\n", React.createElement(ButtonCta, {
    copy: "Choose the best hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-nx-logo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-nx-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-nx-technology",
    "aria-label": "signia nx technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "Signia Nx Technology")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience is a revolutionary technology platform from Signia, the company that has taken over Siemens’ well-known range of audiological devices. Available in Behind-the-Ear and Receiver-in-Canal models, Xperience is one of the most advanced hearing aid technologies around.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-silk.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-silk-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-x",
    "aria-label": "signia silk x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/silk",
    className: "c-md-a"
  }, "Signia Silk X")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is as small as a coffee bean and unnoticeable to those around you, the Signia Silk X is the perfect fit. It’s one of the smallest hearing aids on the market today, disappearing inside your ear. These ITE hearing aids also include “Click Sleeves,” which make them simple and comfortable to wear, as they’re made out of soft silicone that molds itself to the shape of your ear canal.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-dark-granite.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-styletto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto",
    "aria-label": "signia styletto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/styletto",
    className: "c-md-a"
  }, "Signia Styletto")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "This slim, behind-the-ear Bluetooth hearing aid has transformed the future of modern hearing aid design. Designed for the active lifestyle, the rechargeable Styletto X hearing aid combines elegance, convenience and functionality to deliver a high-quality hearing experience without compromising style. And no one will know you’re wearing it.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/binaural-hearing-aids.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure",
    "aria-label": "signia pure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure",
    className: "c-md-a"
  }, "Signia Pure")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia range of Pure hearing aids is incredible no matter your level of hearing loss. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
